// src/i18n.js
import { createI18n } from 'vue-i18n';

// Переводы для различных языков
const messages = {
  ru: {
    forecasts: 'Прогнозы',
    results: 'Результаты',
    time: 'Время',
    teams: 'Команды',
    forecast: 'Прогноз',
    coefficient: 'Коэффициент',
    league: 'Лига',
    result: 'Результат',
    status: 'Статус',
    loading: 'Загрузка данных...',
    selectLanguage: 'Выберите язык:',
  },
  es: {
    forecasts: 'Pronósticos',
    results: 'Resultados',
    time: 'Hora',
    teams: 'Equipos',
    forecast: 'Pronóstico',
    coefficient: 'Coeficiente',
    league: 'Liga',
    result: 'Resultado',
    status: 'Estado',
    loading: 'Cargando datos...',
    selectLanguage: 'Seleccione el idioma:',
  },
  de: {
    forecasts: 'Prognosen',
    results: 'Ergebnisse',
    time: 'Zeit',
    teams: 'Mannschaften',
    forecast: 'Prognose',
    coefficient: 'Koefizient',
    league: 'Liga',
    result: 'Ergebnis',
    status: 'Status',
    loading: 'Daten werden geladen...',
    selectLanguage: 'Sprache auswählen:',
  },
  pt: {
    forecasts: 'Previsões',
    results: 'Resultados',
    time: 'Hora',
    teams: 'Equipes',
    forecast: 'Previsão',
    coefficient: 'Coeficiente',
    league: 'Liga',
    result: 'Resultado',
    status: 'Status',
    loading: 'Carregando dados...',
    selectLanguage: 'Selecione o idioma:',
  },
  fr: {
    forecasts: 'Prévisions',
    results: 'Résultats',
    time: 'Heure',
    teams: 'Équipes',
    forecast: 'Prévision',
    coefficient: 'Coefficient',
    league: 'Ligue',
    result: 'Résultat',
    status: 'Statut',
    loading: 'Chargement des données...',
    selectLanguage: 'Sélectionnez la langue :',
  },
  el: {
    forecasts: 'Προβλέψεις',
    results: 'Αποτελέσματα',
    time: 'Ώρα',
    teams: 'Ομάδες',
    forecast: 'Πρόβλεψη',
    coefficient: 'Συντελεστής',
    league: 'Λίγκα',
    result: 'Αποτέλεσμα',
    status: 'Κατάσταση',
    loading: 'Φόρτωση δεδομένων...',
    selectLanguage: 'Επιλέξτε γλώσσα:',
  },
  pl: {
    forecasts: 'Prognozy',
    results: 'Wyniki',
    time: 'Czas',
    teams: 'Drużyny',
    forecast: 'Prognoza',
    coefficient: 'Współczynnik',
    league: 'Liga',
    result: 'Wynik',
    status: 'Status',
    loading: 'Ładowanie danych...',
    selectLanguage: 'Wybierz język:',
  },
  cs: {
    forecasts: 'Předpovědi',
    results: 'Výsledky',
    time: 'Čas',
    teams: 'Týmy',
    forecast: 'Předpověď',
    coefficient: 'Koeficient',
    league: 'Liga',
    result: 'Výsledek',
    status: 'Stav',
    loading: 'Načítání dat...',
    selectLanguage: 'Vyberte jazyk:',
  }
};

const i18n = createI18n({
  locale: 'ru', // Язык по умолчанию
  fallbackLocale: 'ru', // Язык, используемый если перевод отсутствует
  messages,
});

export default i18n;
