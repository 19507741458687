<template>
  <div class="mx-auto">
    <div v-if="results.length" class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
      <div
        v-for="(result, index) in results"
        :key="index"
        :class="[
          'rounded-lg shadow-md overflow-hidden transition-shadow duration-300 hover:shadow-lg',
          themeClasses.cardBg
        ]"
      >
        <div
          :class="[
            'px-4 sm:px-6 py-3 sm:py-4',
            themeClasses.cardHeaderBg,
            result[5] === '+' ? themeClasses.statusPositive : themeClasses.statusNegative
          ]"
        >
          <h3 :class="['font-bold text-base sm:text-lg mb-0', themeClasses.cardHeaderText]">
            {{ result[1] }}
          </h3>
        </div>

        <div :class="['px-4 sm:px-6 py-3 sm:py-4 card', themeClasses.cardContentBg]">
          <p :class="['sm:text-base mb-2', themeClasses.cardText]">
            <strong>{{ $t('forecast') }}:</strong> {{ result[2] }}
          </p>
          <p :class="['sm:text-base mb-2 date', themeClasses.cardText]">
            <strong></strong> {{ result[0] }}
          </p>
          <p :class="['sm:text-base mb-2', themeClasses.cardText]">
            <strong>{{ $t('coefficient') }}:</strong> {{ result[3] }}
          </p>
          <p :class="['sm:text-base mb-2', themeClasses.cardText]">
            <strong>{{ $t('result') }}:</strong> {{ result[4] }}
          </p>
          <p :class="['sm:text-base mb-2', themeClasses.cardText]">
            <strong>{{ $t('status') }}: </strong>
            <span  :class="[
              result[5] === '+' ? themeClasses.statusPositiveText : themeClasses.statusNegativeText,
              'text-sm font-bold'
            ]">
              {{ result[5] }}
            </span>
          </p>
          <p :class="['text-sm sm:text-base', themeClasses.cardText]">
            <strong>{{ $t('league') }}:</strong> {{ result[6] }}
          </p>
        </div>
      </div>
    </div>
    <div v-else :class="['text-center py-8', themeClasses.loadingText]">
      <div class="loader-container">
        <div class="loader">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, computed, defineProps } from 'vue';
import axios from 'axios';

// Получение пропсов
const props = defineProps({
  language: {
    type: String,
    default: 'ru',
  },
});

// Состояния
const results = ref([]);
const errorMessage = ref(null);

// Функция для загрузки результатов
const fetchResults = async (lang) => {
  try {
    const response = await axios.get(`/api/results?lang=${lang}`);
    results.value = response.data;
    errorMessage.value = null; // Успешная загрузка
  } catch (error) {
    errorMessage.value = 'Не удалось загрузить результаты. Попробуйте снова.';
    console.error('Ошибка загрузки результатов:', error);
  }
};

// Монтаж компонента
onMounted(() => {
  fetchResults(props.language);
});

// Слежение за изменением языка
watch(
  () => props.language,
  (newLang) => {
    fetchResults(newLang);
  }
);

// Определение тем для каждого языка
const themes = {
  ru: {
    // Светлая тема для русского
    cardBg: 'bg-white',
    cardHeaderBg: 'bg-gray-100',
    cardHeaderText: 'text-white',
    cardContentBg: 'bg-white',
    cardText: 'text-gray-700',
    loadingText: 'text-gray-600',
    cardBlock: 'bg-white',
    blockTitle: 'text-2xl font-bold text-gray-700 text-center mb-4',
    // Статусы
    statusPositive: 'bg-green-600',
    statusNegative: 'bg-red-600',
    statusPositiveText: 'text-green-500',
    statusNegativeText: 'text-red-500',
  },
  es: {
    // Тёмная тема для испанского
    cardBg: 'bg-gray-800',
    cardHeaderBg: 'bg-gray-700',
    cardHeaderText: 'text-gray-200',
    cardContentBg: 'bg-gray-800',
    cardText: 'text-gray-300',
    loadingText: 'text-gray-400',
    cardBlock: 'bg-gray-900',
    blockTitle: 'text-2xl font-bold text-white text-center mb-4',
    // Статусы
    statusPositive: 'bg-green-600',
    statusNegative: 'bg-red-600',
    statusPositiveText: 'text-green-500',
    statusNegativeText: 'text-red-500',
  },
  de: {
    cardBg: 'bg-gray-800',
    cardHeaderBg: 'bg-gray-700',
    cardHeaderText: 'text-gray-200',
    cardContentBg: 'bg-gray-800',
    cardText: 'text-gray-300',
    loadingText: 'text-gray-400',
    cardBlock: 'bg-gray-900',
    blockTitle: 'text-2xl font-bold text-white text-center mb-4',
    // Статусы
    statusPositive: 'bg-green-600',
    statusNegative: 'bg-red-600',
    statusPositiveText: 'text-green-500',
    statusNegativeText: 'text-red-500',
  },
  pt: {
    // Зелёная тема для португальского
    cardBg: 'bg-gray-800',
    cardHeaderBg: 'bg-gray-700',
    cardHeaderText: 'text-gray-200',
    cardContentBg: 'bg-gray-800',
    cardText: 'text-gray-300',
    loadingText: 'text-gray-400',
    cardBlock: 'bg-gray-900',
    blockTitle: 'text-2xl font-bold text-white text-center mb-4',
    // Статусы
    statusPositive: 'bg-green-600',
    statusNegative: 'bg-red-600',
    statusPositiveText: 'text-green-500',
    statusNegativeText: 'text-red-500',
  },
  fr: {
    cardBg: 'bg-gray-800',
    cardHeaderBg: 'bg-gray-700',
    cardHeaderText: 'text-gray-200',
    cardContentBg: 'bg-gray-800',
    cardText: 'text-gray-300',
    loadingText: 'text-gray-400',
    cardBlock: 'bg-gray-900',
    blockTitle: 'text-2xl font-bold text-white text-center mb-4',
    // Статусы
    statusPositive: 'bg-green-600',
    statusNegative: 'bg-red-600',
    statusPositiveText: 'text-green-500',
    statusNegativeText: 'text-red-500',
  },
  el: {
    cardBg: 'bg-gray-800',
    cardHeaderBg: 'bg-gray-700',
    cardHeaderText: 'text-gray-200',
    cardContentBg: 'bg-gray-800',
    cardText: 'text-gray-300',
    loadingText: 'text-gray-400',
    cardBlock: 'bg-gray-900',
    blockTitle: 'text-2xl font-bold text-white text-center mb-4',
    // Статусы
    statusPositive: 'bg-green-600',
    statusNegative: 'bg-red-600',
    statusPositiveText: 'text-green-500',
    statusNegativeText: 'text-red-500',
  },
  cs:{
    cardBg: 'bg-gray-800',
    cardHeaderBg: 'bg-gray-700',
    cardHeaderText: 'text-gray-200',
    cardContentBg: 'bg-gray-800',
    cardText: 'text-gray-300',
    loadingText: 'text-gray-400',
    cardBlock: 'bg-gray-900',
    blockTitle: 'text-2xl font-bold text-white text-center mb-4',
    // Статусы
    statusPositive: 'bg-green-600',
    statusNegative: 'bg-red-600',
    statusPositiveText: 'text-green-500',
    statusNegativeText: 'text-red-500',
  },
  pl:{
    cardBg: 'bg-gray-800',
    cardHeaderBg: 'bg-gray-700',
    cardHeaderText: 'text-gray-200',
    cardContentBg: 'bg-gray-800',
    cardText: 'text-gray-300',
    loadingText: 'text-gray-400',
    cardBlock: 'bg-gray-900',
    blockTitle: 'text-2xl font-bold text-white text-center mb-4',
    // Статусы
    statusPositive: 'bg-green-600',
    statusNegative: 'bg-red-600',
    statusPositiveText: 'text-green-500',
    statusNegativeText: 'text-red-500',
  }
};

// Вычисляемое свойство для выбора темы на основе текущего языка
const themeClasses = computed(() => {
  const currentLang = props.language;
  return themes[currentLang] || themes['ru']; // По умолчанию используем русскую тему
});
</script>

<style scoped>
.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
}

.loader {
  display: flex;
  align-items: flex-end;
}

.loader > div {
  width: 15px;
  height: 15px;
  margin: 0 5px;
  background-color: #3498db;
  border-radius: 50%;
  animation: bounce 0.6s infinite alternate;
}

.loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.loader > div:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes bounce {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-30px);
  }
}

h3 {
  font-size: 14px;
}

.card {
  position: relative;
}

.card p {
  font-size: 12px;
}

.date {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 12px;
}
</style>
