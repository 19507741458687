<template>
  <div :class="['min-h-screen py-2 px-4 sm:px-6 lg:px-8', themeClass]">
    <div style="max-width: 1300px" class="mx-auto">
      <LanguageSelector :lang="props.lang" class="mb-8" />
      <div :class="['rounded-lg shadow-lg overflow-hidden', innerThemeClass]">
        <div :class="['flex border-b', borderThemeClass]">
          <button
            v-for="tab in ['forecasts', 'results']"
            :key="tab"
            class="flex-1 py-4 px-6 text-center text-lg font-medium focus:outline-none transition-colors duration-200"
            :class="buttonClass(tab)"
            @click="activeTab = tab"
          >
            {{ $t(tab) }}
          </button>
        </div>
        <!-- Фиксированная минимальная высота -->
        <div class="p-6" style="min-height: 500px;">
          <!-- Используем v-show, чтобы предотвратить размонтирование -->
          <Forecasts v-show="activeTab === 'forecasts'" :lang="props.lang" :language="props.lang" />
          <Results v-show="activeTab === 'results'" :lang="props.lang" :language="props.lang" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, defineProps, computed } from 'vue';
import LanguageSelector from '../../components/LanguageSelector.vue';
import Forecasts from '../../components/ForecastsTab.vue';
import Results from '../../components/ResultsTab.vue';
import { useI18n } from 'vue-i18n';

// Определение пропсов
const props = defineProps({
  lang: {
    type: String,
    default: 'ru',
  },
});

// Получение текущего языка из i18n
const { locale } = useI18n();

// Обновление языка в i18n при изменении пропса
watch(
  () => props.lang,
  (newLang) => {
    locale.value = newLang;
  },
  { immediate: true }
);

// Состояние активной вкладки
const activeTab = ref('forecasts');

// Определение Тем для Каждого Языка
const themes = {
  ru: {
    // Светлая тема для русского
    themeClass: 'bg-white text-black',
    innerThemeClass: 'bg-white',
    borderThemeClass: 'border-gray-300',
    buttonActive: 'bg-gray-200 text-black',
    buttonInactive: 'text-gray-600 hover:bg-gray-200 hover:text-black',
  },
  es: {
    // Тёмная тема для испанского
    themeClass: 'bg-gradient-to-br from-gray-900 to-gray-800 text-white',
    innerThemeClass: 'bg-gray-800',
    borderThemeClass: 'border-gray-700',
    buttonActive: 'bg-gray-700 text-white',
    buttonInactive: 'text-gray-400 hover:bg-gray-700 hover:text-white',
  },
  de: {
    // Синяя тема для немецкого
    themeClass: 'bg-gradient-to-br from-gray-900 to-gray-800 text-white',
    innerThemeClass: 'bg-gray-800',
    borderThemeClass: 'border-gray-700',
    buttonActive: 'bg-gray-700 text-white',
    buttonInactive: 'text-gray-400 hover:bg-gray-700 hover:text-white',
  },
  pt: {
    // Зелёная тема для португальского
    themeClass: 'bg-gradient-to-br from-gray-900 to-gray-800 text-white',
    innerThemeClass: 'bg-gray-800',
    borderThemeClass: 'border-gray-700',
    buttonActive: 'bg-gray-700 text-white',
    buttonInactive: 'text-gray-400 hover:bg-gray-700 hover:text-white',
  },
  fr: {
    // Фиолетовая тема для французского
    themeClass: 'bg-gradient-to-br from-gray-900 to-gray-800 text-white',
    innerThemeClass: 'bg-gray-800',
    borderThemeClass: 'border-gray-700',
    buttonActive: 'bg-gray-700 text-white',
    buttonInactive: 'text-gray-400 hover:bg-gray-700 hover:text-white',
  },
  el: {
    themeClass: 'bg-gradient-to-br from-gray-900 to-gray-800 text-white',
    innerThemeClass: 'bg-gray-800',
    borderThemeClass: 'border-gray-700',
    buttonActive: 'bg-gray-700 text-white',
    buttonInactive: 'text-gray-400 hover:bg-gray-700 hover:text-white',
  },
  cs:{
    themeClass: 'bg-gradient-to-br from-gray-900 to-gray-800 text-white',
    innerThemeClass: 'bg-gray-800',
    borderThemeClass: 'border-gray-700',
    buttonActive: 'bg-gray-700 text-white',
    buttonInactive: 'text-gray-400 hover:bg-gray-700 hover:text-white',
  },
  pl:{
    themeClass: 'bg-gradient-to-br from-gray-900 to-gray-800 text-white',
    innerThemeClass: 'bg-gray-800',
    borderThemeClass: 'border-gray-700',
    buttonActive: 'bg-gray-700 text-white',
    buttonInactive: 'text-gray-400 hover:bg-gray-700 hover:text-white',
  }
};

// Вычисляемые Свойства для Тем
const themeClass = computed(() => {
  const currentLang = props.lang;
  return themes[currentLang]?.themeClass || themes['ru'].themeClass;
});

const innerThemeClass = computed(() => {
  const currentLang = props.lang;
  return themes[currentLang]?.innerThemeClass || themes['ru'].innerThemeClass;
});

const borderThemeClass = computed(() => {
  const currentLang = props.lang;
  return themes[currentLang]?.borderThemeClass || themes['ru'].borderThemeClass;
});

const buttonClass = (tab) => {
  const currentLang = props.lang;
  const theme = themes[currentLang] || themes['ru'];

  return activeTab.value === tab
    ? theme.buttonActive
    : theme.buttonInactive;
};
</script>

<style scoped>
/* Дополнительные стили при необходимости */
</style>
